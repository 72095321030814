<template>
  <div>
    <b-row>
      <b-col sm="12" class="search-col">
        <b-form-input
          :title="i18n['mrp-search-form'].tcSearch"
          id="search"
          :placeholder="i18n['mrp-search-form'].tcSearch"
          v-model="searchText"
          required
        ></b-form-input>
        <b-button
          variant="primary"
          size="sm"
          class="btn btn-primary"
          @click="handleSearchClick()"
          >{{ i18n['mrp-search-form'].tcSearch }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'mrp-search-form',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'mrp-search-form': {
            tcErrorSearchRequires: 'Search requires at least one term.',
            tcSearch: 'Search'

          }
        }
      }
    }
  },
  data() {
    return {
      searchText: '',
    }
  },
  methods: {
    ...mapActions({
      setMRPSearchText: 'mrp/setMRPSearchText',
    }),
    handleSearchClick() {
      if (this.searchText.trim() === '') {
        this.$swal({
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: '#d33',
          text: this.i18n['mrp-search-form'].tcErrorSearchRequires,
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return false
        })
      } else {
        this.setMRPSearchText(this.searchText)
        if (this.$route.name !== 'mrp-search') {
          this.$router.push({ name: 'mrp-search' })
        }
      }

    },
  },
  computed: {
    ...mapGetters({
      mrpSearchText: 'mrp/mrpSearchText',
      mrpSiteCoreBaseURL: 'mrp/mrpSiteCoreBaseURL',
    }),
  },
  async created() {
    if (this.$route.name === 'mrp-search') {
      this.searchText = this.mrpSearchText
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.search-col {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  .btn {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>
